<template>
    <div>
        <h2>{{ $t("compta.comptes_comptables") }}</h2>
        <div class="box">
            <CustomTable
                id_table="accounting_account"
                :items="filtredAccountingAccount"
                :busy.sync="table_busy"
                primaryKey="accountingaccount_id"
                :hide_if_empty="true"
            />
            <AccountingAccountAjout :accountingplan_id="accountingplan_id" @submit="saveCard"/>
            <AccountingAccountImport :accountingplan_id="accountingplan_id" @submit="saveCard"/>
        </div>
    </div>
</template>

<script type="text/javascript">
    import TableAction from "@/mixins/TableAction.js"
    import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
    import Navigation from "@/mixins/Navigation.js"
    import Shutter from "@/mixins/Shutter.js"
    import _sortBy from "lodash/sortBy"
    
    export default {
        name: "AccountingAccount",
        mixins: [TableAction, Accounting, AccountingAccount, Navigation, Shutter],
        props: ['accountingplan_id', 'code_onboarding'],
        data () {
            return {
                accounting_account: null,
                table_busy: true,
                events_tab: {
                    'TableAction::goToDeleteAccountingAccount': (params) => {
                        this.onDelete(params.accountingaccount_id)
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loadAccountingAccountListe()
            },
            async loadAccountingAccountListe() {
                this.table_busy = true
                this.accounting_account = await this.loadAccountingAccounts(this.accountingplan_id)
                this.table_busy = false
            },
            onDelete(accountingaccount_id) {
                this.deleteAccountingAccount(accountingaccount_id).then(() => {
                    this.init_component()
                })
            },
            saveCard() {
                if(this.code_onboarding) {
                    this.ok()
                }
            }
        },
        computed: {
            filtredAccountingAccount: function() {
                return _sortBy(this.accounting_account, ['accountingaccount_number'])
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            AccountingAccountAjout: () => import('@/components/Accounting/AccountingAccountAjout'),
            AccountingAccountImport: () => import('@/components/Accounting/AccountingAccountImport'),
        }
    }
</script>
